import Rails from '@rails/ujs'
import Sortable from "sortablejs";
import { numberToCurrency } from 'javascripts/utils'

export default class CultShop {
  constructor() {
    this.initProductGallery()
    this.initCheckoutAddressForm()
    this.initCartFields()
    this.initCartActions()
    this.initConfigSection()
    this.initHelpLayers()
    this.initInlineItems()
    this.initInlineItemsRemovalButtons()
    this.initRemoteFields()
    this.initSortables()
  }

  initProductGallery() {
    let galleryImages = document.querySelectorAll('[data-hook=gallery-picture]')
    let firstGalleryImage = document.querySelector('[data-hook=gallery-picture]:first-child')
    let thumbnails = document.querySelectorAll('[data-hook=gallery-thumb]')

    galleryImages.forEach( image => {
      image.style.display = 'none'
    })

    if(firstGalleryImage) {
      firstGalleryImage.style.display = 'block'
    }

    thumbnails.forEach( thumbnail => {
      thumbnail.addEventListener('click', function(e) {
        galleryImages.forEach( image => {
          image.style.display = 'none'
        })
        document.querySelector('#' + this.getAttribute('rel')).style.display = 'block'
        e.preventDefault()
      })
    })
  }

  initCheckoutAddressForm() {
    let addressFormToggle = document.querySelector('[data-toggle-disabled]')

    if(addressFormToggle) {
      addressFormToggle.addEventListener('change', function() {
        let targetContainer = document.querySelector(this.getAttribute('data-toggle-disabled'))

        if(targetContainer) {
          let containedFields = targetContainer.querySelectorAll('input, select, textarea')

          if(this.checked) {
            containedFields.forEach( formField => {
              formField.setAttribute('disabled', 'disabled')
              formField.value = ''
            })
          } else {
            containedFields.forEach( formField => {
              formField.removeAttribute('disabled')
            })
          }
        }
      })
    }
  }

  initCartFields() {
    let cartFields = document.querySelectorAll('[data-hook=cart-field]')
    let minusButtons = document.querySelectorAll('[data-hook=cart-item-minus-button]')
    let plusButtons = document.querySelectorAll('[data-hook=cart-item-plus-button]')
    let deleteButtons = document.querySelectorAll('[data-hook=cart-item-delete-button]')

    cartFields.forEach( cartField => {
      cartField.addEventListener('ajax:success', (event) => {
        let data = event.detail[0];
        let item = data.item

        this.updateCartItemTotal(item.id, item.total_price)
        this.updateCartTotalAmount(data.cart_total)
      })
    })

    minusButtons.forEach( minusButton => {
      minusButton.addEventListener('click', function(){
        let textField = this.parentNode.querySelector('[data-hook=cart-field]')
        if (textField.value > 1) {
          textField.value--
          Rails.fire(textField, 'change')
        }
      })
    })

    plusButtons.forEach( plusButton => {
      plusButton.addEventListener('click', function(){
        let textField = this.parentNode.querySelector('[data-hook=cart-field]')
        textField.value++
        Rails.fire(textField, 'change')
      })
    })

    deleteButtons.forEach( deleteButton => {
      deleteButton.addEventListener('ajax:success', (event) => {
        let data = event.detail[0];
        let id = event.target.getAttribute('data-id')
        let item = document.querySelector(`[data-hook=item-${id}]`)
        let cartContainer = document.querySelector('[data-hook=cart]')
        let cartTotal = document.querySelector('[data-hook=cart-total]')
        let nextButton = document.querySelector('[data-hook=checkout-next-button]')

        item.parentNode.removeChild(item)
        this.updateCartTotalAmount(data.cart_total)
        this.updateCartItemIndicator(data.cart_item_count)

        if (data.cart_empty) {
          cartContainer.innerHTML = '<p>Ihr Warenkorb ist leer.</p>'
          cartTotal.remove()
          nextButton.remove()
        }
      })
    })
  }

  updateCartItemTotal(id, price) {
    let itemTotal = document.querySelector(`[data-hook=item-${id}-total]`)
    itemTotal.innerHTML = numberToCurrency(price)
  }

  updateCartTotalAmount(price) {
    let cartTotal = document.querySelector('[data-hook=cart-total-amount]')
    cartTotal.innerHTML = numberToCurrency(price)
  }

  updateCartItemIndicator(count) {
    let cartItemsIndicator = document.querySelector('[data-hook=cart-items-indicator]')
    cartItemsIndicator.innerHTML = count
  }

  initCartActions() {
    let nextButton = document.querySelector('input[data-hook=checkout-next-button]')
    let buyButton = document.querySelector('input[data-hook=checkout-buy-button]')

    if (nextButton) {
      nextButton.addEventListener('click', () => {
        let checkoutForm = document.querySelector('[data-hook=checkout-form]')
        if (checkoutForm) checkoutForm.submit()
      })
    }

    if (buyButton) {
      buyButton.addEventListener('click', (e) => {
        let confirmForm = document.querySelector('[data-hook=confirm-form]')

        if (confirmForm) {
          let requiredCheckboxes = Array.from(document.querySelectorAll('[data-hook=required-terms]'))
          let valid = requiredCheckboxes.every( checkbox => {
            return checkbox.checked
          })

          if (!valid) {
            alert("Sie müssen den AGB, der Widerrufsbelehrung und der Datenschutzerklärung zustimmen.")
            e.preventDefault()
          }
        }
      })
    }
  }

  initConfigSection() {
    this.configureSection = document.querySelector('[data-hook=configure-section]')

    if (this.configureSection) {
      let configFields = document.querySelectorAll('[data-hook=wall-offset-select], [data-hook=config-radio-field]')
      let configTextFields = document.querySelectorAll('[data-hook=config-text-field]')

      configFields.forEach(configField => {
        configField.addEventListener('change', () => {
          this.fetchUpdatedConfigurablesData()
        })
      })

      configTextFields.forEach(configTextField => {
        configTextField.addEventListener('keyup', () => {
          this.fetchUpdatedConfigurablesData()
        })
      })
    }
  }

  fetchUpdatedConfigurablesData() {
    let form = document.querySelector('[data-hook=config-form]')
    let configureURL = form.getAttribute('data-configure-url')

    Rails.ajax({
      url: configureURL,
      type: 'put',
      data: new FormData(form),
      success: (response) => {
        this.updateConfigSectionUI(response)
      }
    })
  }

  updateConfigSectionUI(response) {
    let wallOffsetSelect = this.configureSection.querySelector('[data-hook=wall-offset-select]')
    let submitButton = this.configureSection.querySelector('[data-hook=submit-button]')
    let configuredPrice = this.configureSection.querySelector('[data-hook=configured-price]')
    let priceDetails = this.configureSection.querySelector('[data-hook=price-details]')
    let customRequired = this.configureSection.querySelector('[data-hook=custom-required]')
    let customRequiredLink = this.configureSection.querySelector('[data-hook=custom-required-link]')
    let sketch = this.configureSection.querySelector('[data-hook=sketch]')
    let configurables = [
      'num_rods',
      'diameter',
      'num_rings',
      'wall_offset',
      'num_stands',
      'length',
      'total_length',
      'rod_weight'
    ]

    sketch.setAttribute('src', response.sketch_url)

    configurables.forEach(configurable => {
      let configurableDisplayValue = response.show_option[configurable]
      let optionDisplayElement = this.configureSection.querySelector(`[data-hook=configured-${configurable}]`)

      if (configurableDisplayValue && optionDisplayElement) {
        optionDisplayElement.innerHTML = configurableDisplayValue
      }
    })

    if (wallOffsetSelect) {
      let offsetDropdown = wallOffsetSelect.querySelector(`[value="${response.configured.wall_offset}"]`)
      if (offsetDropdown) offsetDropdown.selected = true
    }

    if (response.configured.custom_required) {
      submitButton.style.display = 'none'
      configuredPrice.style.display = 'none'
      priceDetails.style.display = 'none'
      customRequired.style.display = 'block'
      customRequiredLink.style.display = 'block'
    } else if (response.configured.price) {
      submitButton.style.display = 'block'
      configuredPrice.innerHTML = `${numberToCurrency(response.configured.price)} / Stück`
      configuredPrice.style.display = 'block'
      priceDetails.style.display = 'block'
      customRequired.style.display = 'none'
      customRequiredLink.style.display = 'none'
    } else {
      submitButton.style.display = 'none'
      configuredPrice.style.display = 'none'
      priceDetails.style.display = 'none'
      customRequired.style.display = 'none'
      customRequiredLink.style.display = 'none'
    }
  }

  initHelpLayers() {
    let helpToggles = document.querySelectorAll('[data-hook=help-toggle]')

    helpToggles.forEach( helpToggle => {
      helpToggle.addEventListener('click', function(e){
        let helpItemId = this.getAttribute('data-id')
        let helpLayer = document.querySelector(`[data-hook=help-layer-${helpItemId}]`)

        if (helpLayer.classList.contains('is-hidden')) {
          helpLayer.classList.remove('is-hidden')
        } else {
          helpLayer.classList.add('is-hidden')
        }
        e.preventDefault()
      })
    })
  }

  initInlineItems() {
    let form = document.querySelector('[data-hook=inline-item-form]')

    if (form) {
      let inlineFields = form.querySelectorAll('[data-hook=inline-item-upload-field]')
      let inlineItems = document.querySelector('[data-hook=inline-items]')
      let inlineItemsError = form.querySelector('[data-hook=inline-item-error]')
      let ajaxSpinner = form.querySelector('[data-hook=ajax-spinner]')

      inlineFields.forEach( inlineField => {
        inlineField.addEventListener('change', () => {
          Rails.fire(form, 'submit');
        })
      })

      form.addEventListener('ajax:send', () => {
        ajaxSpinner.style.display = 'block'
      })

      form.addEventListener('ajax:success', (event) => {
        let newElement = event.detail[2].responseText

        inlineItems.insertAdjacentHTML('beforeend', newElement)
        inlineItemsError.innerHTML = ''
        form.reset()

        let lastInlineItem = inlineItems.querySelector('[data-hook^=inline-item-]:last-child')
        let inlineItemRemovalLink = lastInlineItem.querySelector('[data-hook=delete-inline-item]')
        this.attachInlineItemsRemovalEventListener(inlineItemRemovalLink)

        ajaxSpinner.style.display = 'none'
      })

      form.addEventListener('ajax:error', (event) => {
        let xhr = event.detail[2]
        let errorMessage = (xhr.status == 500) ? 'Ein Fehler ist auftegreten' : xhr.responseText
        inlineItemsError.innerHTML = errorMessage
        ajaxSpinner.style.display = 'none'
      })
    }
  }

  initInlineItemsRemovalButtons() {
    let inlineItemRemovalLinks = document.querySelectorAll('[data-hook=delete-inline-item]')

    inlineItemRemovalLinks.forEach( removalLink => {
      this.attachInlineItemsRemovalEventListener(removalLink)
    })
  }

  attachInlineItemsRemovalEventListener(element) {
    let form = document.querySelector('[data-hook=inline-item-form]')

    element.addEventListener('ajax:success', function() {
      let inlineItem = document.querySelector(`[data-hook=inline-item-${this.getAttribute('data-id')}]`)
      inlineItem.parentNode.removeChild(inlineItem)
    })
    element.addEventListener('ajax:error', (event) => {
      let inlineItemsError = form.querySelector('[data-hook=inline-item-error]')
      inlineItemsError.innerHTML = event.detail[2].responseText
    })
  }

  initRemoteFields() {
    let remoteFields = document.querySelectorAll('input[data-remote]')

    remoteFields.forEach( remoteField => {
      remoteField.addEventListener('ajax:success', function() {
        let container = this.parentNode
        container.classList.remove('animate-highlight')
        void container.offsetWidth;
        container.classList.add('animate-highlight')
      })
      remoteField.addEventListener('ajax:error', function() {
        this.parentNode.classList.add('txt-error')
        this.after(' Wert konnte nicht gespeichert werden.')
      })
    })
  }

  initSortables() {
    let sortables = document.querySelectorAll('[data-sortable=true]')

    sortables.forEach( singleSortable => {
      let ajaxURL = singleSortable.getAttribute('data-reorder-url')

      Sortable.create(singleSortable, {
        onUpdate: function() {
          let order = this.toArray();
          let formData = new FormData()

          order.forEach(id => {
            formData.append('order[]', id)
          })

          Rails.ajax({
            url: ajaxURL,
            type: 'put',
            data: formData,
            success: () => {
              this.el.classList.remove('animate-highlight')
              void this.el.offsetWidth;
              this.el.classList.add('animate-highlight')
            }
          })
        }
      })
    })
  }
}
