
import 'cookieconsent'

export function initCookieConsent () {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#FAFAF6',
        text: '#000'
      }
    },
    position: 'bottom',
    content: {
      message: 'Diese Website verwendet Cookies. Weitere Informationen finden Sie in unserer',
      dismiss: 'Schließen',
      link: 'Datenschutzerklärung',
      href: 'https://www.cult-schmiede.de/info/seite/Datenschutz'
    }
  })
}
