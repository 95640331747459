import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { initCookieConsent } from 'javascripts/cookieconsent'
import { initGLightbox } from 'javascripts/glightbox'
import Rails from '@rails/ujs'
import CultShop from 'javascripts/cultshop'
import 'stylesheets/application.scss'

require.context('../images', true)
// const imagePath = (name) => images(name, true)

Rails.start()

initCookieConsent()
initGLightbox()

new CultShop()
